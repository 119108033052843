import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IProfileResponse } from "../../app/services/profile";
import { ApiResponse } from "../../app/commonTypes";
import { RootState } from "../../app/store";
import { encryptData } from "../../localStorage.helper";

const slice = createSlice({
  name: "dashboard",
  initialState: { profile: {}, location: {},mustRefresh:false } as IProfileResponse,
  reducers: {
    setProfile: (
      state,
      { payload }: PayloadAction<ApiResponse<IProfileResponse>>
    ) => {
      localStorage.setItem(
        "profile",
        encryptData(JSON.stringify(payload.model)) as any
      );
      return payload.model;
    },
    setRefreshToken: (
      state,
      { payload }:  any
    ) => {
      state.mustRefresh = false;
      localStorage.setItem('token',   encryptData(JSON.stringify(payload.model.accessToken)) as any);
      localStorage.setItem('expires', encryptData(JSON.stringify(payload.model.expires)) as any );
      window.location.reload();
    },
    setMustRefresh: (
      state,
      { payload }: any
    ) => {
      return {
        ...state,
        mustRefresh: true
      }
    },

  },
});

export const { setProfile, setRefreshToken,setMustRefresh  } = slice.actions;

export default slice.reducer;

export const selectProfile = (state: RootState) => state.dashboard.profile;
export const selectLocation = (state: RootState) => state.dashboard.location;
export const selectMustRefreshToken = (state: RootState) => state.dashboard.mustRefresh;
