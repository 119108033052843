import React from "react";
import { Toast, UserCard } from "@gomycode/design-system";
import styles from "./Dashboard.module.css";
import dayjs from "dayjs";
export const dateFormatter = (date: string): string => {
  const newDate = new Date(date);
  let day = newDate.getUTCDate().toString();
  const year = newDate.getFullYear();
  let month = (newDate.getUTCMonth() + 1 ).toString();
  if (month.length === 1) {
    month = `0${month}`;
  }
  if (day.length === 1) {
    day = `0${day}`;
  }
  return `${day}/${month}/${year}`;
};

export const columns = () => [
  {
    title: "ProductSchedule",
    dataIndex: "productSchedule",
    key: "productSchedule",
    render: (productSchedule: {
      productSchedule: string;
      availableSeats: number;
    }) => (
      <Toast
        className={styles.productScheduleTag}
        type={productSchedule.availableSeats > 0 ? "success" : "failure"}
        withBorder={false}
        title={productSchedule.productSchedule}
      />
    ),
  },
  {
    title: "Available",
    dataIndex: "availableSeats",
    key: "availableSeats",
    render: (availableSeats: number) => <span>{availableSeats}</span>,
  },
  {
    title: "Target",
    dataIndex: "targetSeats",
    key: "targetSeats",
    render: (targetSeats: number) => <span>{targetSeats}</span>,
  },
  {
    title: "Subscribed",
    dataIndex: "subscribed",
    key: "subscribed",
    render: (subscribed: number) => <span>{subscribed}</span>,
  },
  {
    title: "Instructor",
    dataIndex: "instructor",
    key: "instructor",
    render: (instructor: any) => {
      return (
        <UserCard
          className={styles.userCard}
          image={instructor.picture}
          name={instructor.fullName}
        />
      );
    },
  },
  {
    title: "Start date",
    dataIndex: "startDate",
    key: "startDate",
    render: (startDate: string) => <span>{dayjs(startDate).format('MMMM DD, YYYY')}</span>,
  },
  {
    title: "End date",
    dataIndex: "endDate",
    key: "endDate",
    render: (endDate: string) => <span>{dayjs(endDate).format('MMMM DD, YYYY')}</span>,
  },
];

export const makeDataSource = (data: any) => {
  const result = [];
  for (let i = 0; i < data.openings.length; i++) {
    result.push({
      productSchedule: {
        productSchedule: data.openings[i].productSchedule.name,
        availableSeats:
          data.openings[i].usesRelationship.targetCapacity -
          data.openings[i].usesRelationship.numberOfSubscribedStudents,
      },
      availableSeats:
        data.openings[i].usesRelationship.targetCapacity -
        data.openings[i].usesRelationship.numberOfSubscribedStudents,
      targetSeats: data.openings[i].usesRelationship.targetCapacity,
      subscribed: data.openings[i].usesRelationship.numberOfSubscribedStudents,
      instructor: data.openings[i].instructor,
      startDate: data.openings[i].opening.startDate,
      endDate: data.openings[i].opening.dueDate,
      trackName: data.track.name,
      timeslots: data.openings[i].productTimeSlots
    });
  }
  return result;
};
