import { Toast } from "@gomycode/design-system";
import { Table, Popover, Divider } from "antd";
import React, { Fragment } from "react";
import { hideLongText } from "../../../constants";
import { columns, makeDataSource } from "../tableHelper";
import styles from "./TrackCard.module.css";
import './override.css'
type ITrackCardProps = {
  availability: any;
};

const weekMap: any = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};

const TrackCard: React.FC<ITrackCardProps> = ({ availability }) => {
  const availableSeatsPerOpening = (i: number) => {
    return (
      availability.openings[i].usesRelationship.targetCapacity -
      availability.openings[i].usesRelationship.numberOfSubscribedStudents
    );
  };

  const getTargetSeats = (): number => {
    return availability.openings.reduce((acc: number, { usesRelationship }: any) => {
      return acc + usesRelationship.targetCapacity;
    }, 0);
  };

  const getSubscribedStudents = (): number => {
    return availability.openings.reduce((acc: number, { usesRelationship }: any) => {
      return acc + usesRelationship.numberOfSubscribedStudents;
    }, 0);
  };
  return (
    <div className={styles.trackCardContainer}>
      <div className={styles.trackCard}>
        <div className={styles.leftSection}>
          <img
            className={styles.trackCardImage}
            src={availability.track.image}
            alt="track"
          />
          <div className={styles.trackNameContainer}>
            <p className={styles.trackName}>
              {" "}
              {hideLongText(availability.track.name, 35)}
            </p>
            <div className={styles.productScheduleContainer}>
              {availability.openings.map(
                ({ productSchedule, productTimeSlots }: any, i: number) => {
                  const content = productTimeSlots.map((el: any) => {
                    return (
                      <Fragment>
                        {/* <Divider /> */}
                        <hr />
                        <span className={styles.contentElement}>{`${
                          weekMap[el.day]
                        } ${el.startTime.slice(0, 5)} ${el.dueTime.slice(
                          0,
                          5
                        )}`}</span>
                      </Fragment>
                    );
                  });
                  const title = <span className={styles.titleElement}>
                    {`${productSchedule.name} Schedule`}
                  </span>
                  return (
                    <Popover title={title} content={content}>
                      <div>
                        <Toast
                          type={
                            availableSeatsPerOpening(i) > 0
                              ? "success"
                              : "failure"
                          }
                          withBorder={false}
                          title={productSchedule.name}
                          className={styles.productScheduleTag}
                        />
                      </div>
                    </Popover>
                  );
                }
              )}{" "}
            </div>
          </div>
        </div>
        <div className={styles.rightSection}>
          <Toast
            type={
              getTargetSeats() - getSubscribedStudents() > 0
                ? "success"
                : "failure"
            }
            withBorder={false}
            title={`Available Seats : ${
              getTargetSeats() - getSubscribedStudents()
            }`}
            leftIcon={
              <svg
                width="13"
                height="15"
                viewBox="0 0 13 15"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1.75 4.1875C1.75 4.37891 1.77734 4.54297 1.83203 4.70703L2.1875 5.5H8.75C9.21484 5.5 9.625 5.91016 9.625 6.375C9.625 6.86719 9.21484 7.25 8.75 7.25H2.92578L3.30859 8.125H11.375C11.7031 8.125 12.0039 8.31641 12.1406 8.61719C12.3047 8.91797 12.25 9.27344 12.0586 9.54688L10.8828 11.1055C10.6367 11.4336 10.2539 11.625 9.84375 11.625H7.21875V12.9375H9.84375C10.1992 12.9375 10.5 13.2383 10.5 13.5938C10.5 13.9766 10.1992 14.25 9.84375 14.25H3.28125C2.89844 14.25 2.625 13.9766 2.625 13.5938C2.625 13.2383 2.89844 12.9375 3.28125 12.9375H5.90625V11.625H4.64844C3.58203 11.625 2.65234 11.0234 2.21484 10.0391L0.246094 5.39062C0.0820312 5.00781 0 4.59766 0 4.1875V1.125C0 0.660156 0.382812 0.25 0.875 0.25C1.33984 0.25 1.75 0.660156 1.75 1.125V4.1875Z" />
              </svg>
            }
            className={styles.productScheduleTag}
          />
          <Toast
            type="disabled"
            withBorder={false}
            title={`Target Seats : ${getTargetSeats()}`}
            className={styles.productScheduleTag}
            leftIcon={
              <svg
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.0078 6.10156L10.8008 6.23828C10.8828 6.56641 10.9375 6.89453 10.9375 7.25C10.9375 9.4375 9.16016 11.1875 7 11.1875C4.8125 11.1875 3.0625 9.4375 3.0625 7.25C3.0625 5.08984 4.8125 3.3125 7 3.3125C7.35547 3.3125 7.68359 3.36719 8.01172 3.44922L8.14844 4.24219L7.30078 5.08984C7.19141 5.08984 7.08203 5.0625 7 5.0625C5.76953 5.0625 4.8125 6.04688 4.8125 7.25C4.8125 8.48047 5.76953 9.4375 7 9.4375C8.20312 9.4375 9.1875 8.48047 9.1875 7.25C9.1875 7.16797 9.16016 7.05859 9.16016 6.94922L10.0078 6.10156ZM13.5898 4.95312C13.8359 5.66406 14 6.45703 14 7.25C14 11.1328 10.8555 14.25 7 14.25C3.11719 14.25 0 11.1328 0 7.25C0 3.39453 3.11719 0.25 7 0.25C7.79297 0.25 8.58594 0.414062 9.29688 0.660156L7.875 2.08203C7.57422 2.02734 7.27344 2 7 2C4.07422 2 1.75 4.35156 1.75 7.25C1.75 10.1484 4.07422 12.5 7 12.5C9.89844 12.5 12.25 10.1484 12.25 7.25C12.25 6.97656 12.2227 6.67578 12.168 6.375L13.5898 4.95312ZM7.4375 7.71484C7.19141 7.98828 6.78125 7.98828 6.53516 7.71484C6.26172 7.46875 6.26172 7.05859 6.53516 6.78516L9.05078 4.26953L8.80469 2.90234C8.77734 2.62891 8.85938 2.32812 9.05078 2.13672L10.4453 0.742188C10.6641 0.523438 11.0469 0.578125 11.1836 0.878906L11.8125 2.4375L13.3711 3.06641C13.6719 3.20312 13.7266 3.55859 13.5078 3.80469L12.1133 5.19922C11.9219 5.39062 11.6211 5.47266 11.3477 5.44531L9.98047 5.19922L7.4375 7.71484Z"
                  fill="#828A92"
                />
              </svg>
            }
          />
          <Toast
            type="disabled"
            withBorder={false}
            title={`Subscribed Seats : ${getSubscribedStudents()}`}
            className={styles.productScheduleTag}
            leftIcon={
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.9375 2.875C5.64844 2.875 6.22266 2.30078 6.22266 1.5625C6.22266 0.851562 5.64844 0.25 4.9375 0.25C4.19922 0.25 3.625 0.851562 3.625 1.5625C3.625 2.30078 4.19922 2.875 4.9375 2.875ZM12.375 11.625H11.6641L10.543 8.69922C10.4336 8.37109 10.1055 8.125 9.75 8.125H7.04297L6.87891 7.25H9.06641C9.55859 7.25 9.94141 6.86719 9.94141 6.375C9.94141 5.91016 9.53125 5.5 9.06641 5.5H6.55078L6.44141 4.84375C6.30469 4.13281 5.62109 3.64062 4.91016 3.77734C4.19922 3.91406 3.70703 4.59766 3.84375 5.30859L4.47266 8.80859C4.63672 9.4375 5.18359 9.875 5.78516 9.875C5.8125 9.875 5.86719 9.875 5.92188 9.875C5.97656 9.875 6.00391 9.875 6.03125 9.875H9.12109L10.2422 12.8281C10.3516 13.1562 10.6797 13.375 11.0625 13.375H12.3477C12.8125 13.375 13.1953 12.9922 13.1953 12.5C13.1953 12.0352 12.8398 11.625 12.375 11.625ZM7.78125 10.75H5.34766C4.5 10.75 3.78906 10.1758 3.625 9.32812L2.72266 4.48828C2.64062 3.99609 2.17578 3.69531 1.71094 3.77734C1.21875 3.85938 0.917969 4.32422 1 4.78906L1.90234 9.65625C2.23047 11.3242 3.67969 12.5 5.34766 12.5H7.78125C8.24609 12.5 8.62891 12.1172 8.62891 11.625C8.62891 11.1602 8.24609 10.75 7.78125 10.75Z"
                  fill="#828A92"
                />
              </svg>
            }
          />
        </div>
      </div>
      <Table
        pagination={false}
        dataSource={makeDataSource(availability)}
        columns={columns()}
        rowClassName={(record, index) =>
          index % 2 === 0 ? styles.tableRowLight : styles.tableRowDark
        }
      />
    </div>
  );
};

export default TrackCard;
