import { Tooltip } from "antd";

export const FILTER_RULES = {
  SEARCH: {
    CONTAIN: "CONTAIN",
    DOES_NOT_CONTAIN: "DOES_NOT_CONTAIN",
  },

  CHECKABLE_SEARCH: {
    IS_CHECKED: "IS_CHECKED",
  },
  SIMPLE_SEARCH: {
    CONTAIN: "CONTAIN",
  },
};
export const FILTER_TYPES = {
  SEARCH: "SEARCH_FILTER_TYPE",
  CHECKABLE_SEARCH: "CHECKABLE_SEARCH_FILTER_TYPE",
  SIMPLE_SEARCH: "SIPMLE_SEARCH",
};
export const CASE_INSENSITIVE_OPERATORS = ["contains", "not_contains"];

export const getQueryString = (queries: any = []) => {
  const noEmptyValuesQueries = queries.filter(
    (query: any) => query.value !== ""
  );
  if (queries.length > 0) {
    const encodedQuery = noEmptyValuesQueries
      .map((query: any) => {
        if (CASE_INSENSITIVE_OPERATORS.includes(query.operator))
          return `${query.key}${
            query.operator ? `[${query.operator}]` : ""
          }~=${query.value.toLowerCase()}`;
        return `${query.key}${query.operator ? `[${query.operator}]` : ""}=${
          query.value
        }`;
      })
      .join("&");
    return `?${encodedQuery}`;
  }

  return "";
};

export const hideLongText = (text: string, maxLength = 20) => {
  const allText = <span className="hide-long-text">{text}</span>;
  if (text.length > maxLength) {
    return (
      <Tooltip placement="right" title={allText}>
        <span className="hide-long-text">{`${text.slice(
          0,
          maxLength
        )}...`}</span>
      </Tooltip>
    );
  }

  return text;
};

export const encodeUnattachedFilter = (
  key: string | number,
  operator: string,

  value?: string
) => {
  const unattachedFilter = { key, value, operator };

  return unattachedFilter;
};

export const encodeUnattachedSorting = (
  sorterName: string,
  sortingPriorityIndex = 0,
  sortingOrder = "+"
) => ({
  sorterName,
  sortingPriorityIndex,
  sortingOrder,
});
