import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getQueryString } from "../../constants";

export const capacityApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_LEARN_ENDPOINT,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      } else {
        // Redirection logic
      }

      return headers;
    },
  }),
  reducerPath: "capacityApi",
  endpoints: (builder) => ({
    getOpeningsAvailability: builder.mutation<any, any>({
      query: (params: any) => {
        const { query } = params;
        const queryString = getQueryString(query);
        return {
          url: "capacity/openings/new-availabilities" + queryString,
          method: "POST",
          body: params,
        };
      },
    }),
    getTrainingSessions: builder.query<any, void>({
      query: () => ({
        url: "capacity/training-sessions?limit=12&ia=false",
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetOpeningsAvailabilityMutation,
  useGetTrainingSessionsQuery,
} = capacityApi;
