import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { useGetProfileQuery } from "../../app/services/profile";
import styles from "./Dashboard.module.css";
import {
  useGetOpeningsAvailabilityMutation,
  useGetTrainingSessionsQuery,
} from "../../app/services/capacity";
import { DSFilter, encodeFilters, SelectInput } from "@gomycode/design-system";
import { encodeUnattachedFilter, FILTER_TYPES } from "../../constants";
import { setProfile } from "./dashboardSlice";
import TrackCard from "./TrackCard";
import Modal from "./Modal";
import "./dashboard-overrided.css";
import { useGetLocationsListQuery } from "../../app/services/externalServices";
import { Col, Row } from "antd";
import MobileTrackCard from "./MobileTrackCard";
import NoDataSelected from "./NoDataSelected";
interface IDashboardProps {}

const Dashboard: React.FC<IDashboardProps> = () => {
  const dispatch = useDispatch();
  const [dataFilters, setDataFilters] = useState<any>({ filtersRules: [] });
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedAvailability, setSelectedAvailability] = useState<any>({});
  const [selectedTrainingSessionId, setSelectedTrainingSessionId] =
    useState("");
  const [locationId, setLocationId] = useState("");

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const { data: profile, refetch: getProfile } = useGetProfileQuery();
  const [getOpeningsAvailability, { data: availabilities }] =
    useGetOpeningsAvailabilityMutation();

  const { refetch: getTrainingSessions, data: trainingSessions } =
    useGetTrainingSessionsQuery();

  const { refetch: getLocationsList, data: locations } =
    useGetLocationsListQuery({
      query: [
      ],
    });

  const filters = [
    {
      filterName: "trackName",
      filterLabel: "Track",
      filterType: FILTER_TYPES.SIMPLE_SEARCH,
      filterOptions: [],
      loading: false,
      sortingPriorityIndex: 2,
      placeHolder: "Search for a track",
    },
  ];
  const filtersRules: any = [];
  useEffect(() => {
    getProfile();
    getTrainingSessions();
  }, [getProfile, getTrainingSessions]);

  useEffect(() => {
    if (profile) {
      dispatch(setProfile(profile));
    }
  }, [profile]);

  useEffect(() => {
    getLocationsList();
  }, []);
  useEffect(() => {
    if (selectedTrainingSessionId && locationId) {
      getOpeningsAvailability({
        locationId: locationId,
        trainingSessionId: selectedTrainingSessionId,
        query: [...encodeFilters(dataFilters.filtersRules, filters)],
      });
    }
  }, [locationId, selectedTrainingSessionId, dataFilters.filtersRules]);
  const setFiltersRules = (fr: []) =>
    setDataFilters({
      operationName: "x",
      filtersRules: fr,
    });

  return (
    <div className={styles.dashboard}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sales App</title>
      </Helmet>
      <h1 className={styles.title}>Capacity</h1>
      <Row gutter={[0, 17]} className={styles.filtersContainer}>
        {selectedAvailability.targetSeats && (
          <Modal
            selectedAvailability={selectedAvailability}
            open={showModal}
            onCancel={toggleModal}
          />
        )}
        <Col lg={15} md={24} xs={24} sm={24}>
          <DSFilter
            filters={filters}
            filtersRules={filtersRules}
            setFiltersRules={setFiltersRules}
            className={styles.salesFilter}
          />
        </Col>
        <Col
          sm={24}
          xs={24}
          md={24}
          lg={9}
          className={styles.selectInputContainer}
        >
          <Row gutter={16}>
            <Col span={12}>
              <SelectInput
                showSearch
                optionFilterProp='label'
                onSelect={(e) => setLocationId(e)}
                data={
                  locations?.model
                    .map((el: any) => ({
                      label:el?.location?.name,
                      value:el?.location?.id,
                    }))
                    .sort() || []
                }
                placeholder="Select hackerspace"
              />
            </Col>
            <Col span={12}>
              <SelectInput
                showSearch
                optionFilterProp='label'
                onSelect={(e) => setSelectedTrainingSessionId(e)}
                data={
                  trainingSessions?.model.map((el: any) => ({
                    label: el.name,
                    value: el.id,
                  })) || []
                }
                placeholder="Select training session"
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {!availabilities ? (
        <NoDataSelected />
      ) : (
        availabilities?.model.map((availability: any) => {
          return (
            <Fragment>
              <Row>
                <Col xl={24} xxl={24} lg={24} sm={0} md={0} xs={0}>
                  <TrackCard availability={availability} />
                </Col>
                <Col xl={0} xxl={0} lg={0} xs={24} sm={24} md={24}>
                  <MobileTrackCard
                    setSelectedAvailability={setSelectedAvailability}
                    showModal={toggleModal}
                    availability={availability}
                  />
                </Col>
              </Row>
            </Fragment>
          );
        })
      )}
      {}
    </div>
  );
};

export default Dashboard;
