import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ApiResponse } from "../commonTypes";

type Profile = {
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  hubSpotContactId: string;
  phoneNumber: string;
  address: string;
  emailUsername: string;
  picture: string;
  dateOfBirth: string;
  isIndexed: boolean;
  id: string;
  isArchived: boolean;
};

type Location = {
  address: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  longitude: number;
  latitude: number;
  image: string;
  capacity: number;
  numberOfStudents: number;
  numberOfActiveStudents: number;
  numberOfInstructors: number;
  numberOfHours: number;
  bonusPerHour: number;
  durationBetweenFeedback: number;
  dynamicsUrl: string;
  potentialDropoutEmailInDays: number;
  isAutomatedTimeSlotEnabled: boolean;
  name: string;
  isOnlineEnabled: boolean;
  numberOfMinutesBeforeSessionStarts: number;
  numberOfMinutesAfterSessionEnds: number;
  isRoomLockedByDefault: boolean;
  isOnlineAutomatedAttendanceEnabledForInstructors: boolean;
  isOnlineAutomatedAttendanceEnabledForStudents: boolean;
  numberOfMinutesOfLateArrivalToBeMarkedAsAbsentForStudent: number;
  numberOfMinutesOfAbsenceToBeMarkedAsAbsentForStudent: number;
  numberOfMinutesOfLateArrivalToBeMarkedAsAbsentForInstructor: number;
  numberOfMinutesOfAbsenceToBeMarkedAsAbsentForInstructor: number;
  isOnlineStreamingEnabled: boolean;
  isOnlineRecordingEnabled: boolean;
  timeOffset: number;
  referralPrice: number;
  numberOfDaysToValidateReferral: number;
  isReferralVisibleByStudents: boolean;
  referralDiscount: string;
  referralPopupIntervalInDays: number;
  hubspotPipeline: string;
  isVisibleOnWebsite: boolean;
  hasRoomManagement: boolean;
  id: string;
  isArchived: boolean;
};

export type IProfileResponse = {
  profile?: Profile;
  location?: Location;
   mustRefresh?:any
};

export const profileApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_LEARN_ENDPOINT,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("token");

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      } else {
        // Redirection logic
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getProfile: builder.query<ApiResponse<IProfileResponse>, void>({
      query: () => ({
        url: "account/profiles",
        method: "GET",
      }),
    }),
  }),
});

export const { useGetProfileQuery } = profileApi;
